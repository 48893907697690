
import { Vue, Component } from 'vue-property-decorator';
import {TakipVeTahsilatDataTableEntity} from "@/entity/Raporlama/TakipVeTahsilatDataTableEntity";

@Component
export default class TakipVeTahsilatDataTable extends Vue {
  search: string = '';


  items:Array<TakipVeTahsilatDataTableEntity>=[]
  headers = [
    { text: 'Zaman', align: 'start', sortable: false, value: 'ay', class:'header-style' },
    { text: 'Takip Toplamı', value: 'takipToplam', class:'header-style' },
    { text: 'Tahsilat Toplamı', value: 'tahsilatToplam', class:'header-style' },
    { text: 'Takip/Tahsilat Oranı', value: 'takipTahsilatOran', class:'header-style' },
  ];
  sutunlar: string[] = this.headers.map(header => header.value);


  mounted(){
    this.fetchData()
  }

  getMonthName(month: number): string {
    const monthNames = [
      'Ocak',
      'Şubat',
      'Mart',
      'Nisan',
      'Mayıs',
      'Haziran',
      'Temmuz',
      'Ağustos',
      'Eylül',
      'Ekim',
      'Kasım',
      'Aralık'
    ];
    return monthNames[month - 1] || '';
  }
  async fetchData(){
    try {
      const response = await this.$http.post('/api/v1/takip-tahsilat-dosya-getir/',  {
        "userId" : 2,
        "basTar" : "2023-01-01",
        "bitTar" : "2024-01-01",
        "interval" : "yearly"
      });
      const data = response.data;
      this.items = data.map((takipVeTahsilatItems:any)=>{
        return takipVeTahsilatItems
      })
    }catch (error) {
      console.error('hata:', error);
    }
  }

  get seciliHeader() {
    return this.headers.filter((header) => this.sutunlar.includes(header.value));
  }
}
