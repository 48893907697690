
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import OzelMenuRaporlama from "@/components/infos/raporlama-ofispro/helper-comps/OzelMenuRaporlama.vue";

@Component({
  components: {OzelMenuRaporlama}

})
export default class TakipVeTahsilatKarsilastirmaliGrafik extends Vue{

  options = {
    series: [{
      name: 'Toplam Takip',
      data: {},
      color: '#5D7285',
    }, {
      name: 'Toplam Tahsilat',
      data: {},
      color: '#5FB3F6',
    }],
    chart: {
      type: 'bar',
      height: 350,
      offsetY: 40,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors:["#5D7285","#5FB3F6"]
    },
    xaxis: {
      categories: ['Ocak','Şubat', 'Mart', 'Nisan', 'Mayıs', 'Haziran', 'Temmuz', 'Ağustos', 'Eylül', 'Ekim','Kasım','Aralık'],
      offsetY: -10,
    },
    yaxis: {
      title: {
        text: '₺ (bin)'
      }
    },
    fill: {
      opacity: 1,
      colors:["#5D7285","#5FB3F6"]
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          return "₺ " + val
        }
      }
    }
  };

  mounted() {
    this.fetchData()
  }

  async fetchData(){
    try {
      const response = await this.$http.post('/api/v1/takip-tahsilat-dosya-getir/',  {
        "userId" : 2,
        "basTar" : "2023-01-01",
        "bitTar" : "2024-01-01",
        "interval" : "yearly"
      });
      const data = response.data;


      const seriesData = Array(12).fill(0);
      const seriesDataTahsilat = Array(12).fill(0);

      for (const entry of data) {
        const monthIndex = entry.ay - 1;
        seriesData[monthIndex] = Number(entry.takipToplam);
        seriesDataTahsilat[monthIndex] = Number(entry.tahsilatToplam);
      }

      this.options.series = [
        {
          name: 'Toplam Takip',
          data: seriesData,
          color: '#5D7285',
        },
        {
          name: 'Toplam Tahsilat',
          data: seriesDataTahsilat,
          color: '#5FB3F6',
        }
      ];

    }catch (error) {
      console.error('hata:', error);
    }
  }
}
