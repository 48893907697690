
import {Vue, Component, Prop, Watch} from "vue-property-decorator";
import OzelMenuRaporlama from "@/components/infos/raporlama-ofispro/helper-comps/OzelMenuRaporlama.vue";

@Component({
  components: {OzelMenuRaporlama}

})
export default class TakipVeTahsilatAyGrafik extends Vue{

  options = {
    series: [{
      name: 'Toplam Takip',
      data: [13122, 31351, 313322, 31133],
      color: '#5D7285',
    }, {
      name: 'Toplam Tahsilat',
      data: [156721, 323332, 23323, 13313],
      color: '#5FB3F6',
    }],
    chart: {
      type: 'bar',
      height: 350,
      offsetY: 40,
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '25%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 1,
      colors:["#5D7285","#5FB3F6"]
    },
    xaxis: {
      categories: ['1.Hafta', '2.Hafta', '3.Hafta', '4.Hafta'],
      offsetY: -10,
    },
    yaxis: {
      title: {
        text: '₺ (bin)'
      }
    },
    fill: {
      opacity: 1,
      colors:["#5D7285","#5FB3F6"]
    },
    tooltip: {
      y: {
        formatter: function (val: string) {
          return "₺ " + val
        }
      }
    }
  };
}
