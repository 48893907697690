
import GenelIstatistikPerformansGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikPerformansGrafik";
import {Component, Mixins, Vue, Watch} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

  @Component({
    components: {
      GenelIstatistikPerformansGrafik,
    }
  })

  export default class TakipTahsilatWidget extends Mixins(ObjectInputMixin){

    cards: {
      id: number;
      icon: string;
      title: string;
      flex: string|number;
      text: string;
    }[] = [];

    takipToplamChart:any =[]
    tahsilatToplamChart:any = []


    mounted(){
      this.fetchData()
    }

    async fetchData(){
      try {
        const response = await this.$http.post('/api/v1/takip-tahsilat-dosya-getir/',  {
          "userId" : 2,
          "basTar" : "2024-01-01",
          "bitTar" : "2024-12-31",
          "interval" : "yearly"
        });
        const data = response.data;
        for (var i = 0; i < data.length; i++) {
          this.takipToplamChart.push(data[i].takipToplam);
          this.tahsilatToplamChart.push(data[i].tahsilatToplam);
        }

        let takipToplamGoster = this.takipToplamChart.reduce((acc:any, value:any) => acc + Number(value), 0);
        let tahsilatToplamGoster = this.tahsilatToplamChart.reduce((acc:any, value:any) => acc + Number(value), 0);

        this.cards = [
          {
            id: 1,
            icon: 'mdi-pencil-box',
            title: 'Takip',
            flex: 6,
            text: `${takipToplamGoster} ₺`,
          },
          {
            id: 2,
            icon: 'mdi-cash-plus',
            title: 'Tahsilat',
            flex: 6,
            text: `${tahsilatToplamGoster} ₺`,
          },
        ];
      }catch (error) {
        console.error('hata:', error);
      }
    }

  }
