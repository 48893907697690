
import GenelIstatistikPerformansGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikPerformansGrafik";
import MuvekkilTableInfo from "@/components/infos/raporlama-ofispro/MuvekkilTableInfo";
import UstBasliklar from "@/components/infos/raporlama-ofispro/helper-comps/UstBasliklar";
import TahsilatlarChart from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarChart";
import TahsilatlarDataTable from "@/components/infos/raporlama-ofispro/helper-comps/TahsilatlarDataTable";
import Dates from "@/components/inputs/Dates";
import {Component, Vue} from "vue-property-decorator";
import GenelIstatistikGrafikInfo from "@/components/infos/raporlama-ofispro/helper-comps/GenelIstatistikGrafikInfo";
import OzelMenuRaporlama from "@/components/infos/raporlama-ofispro/helper-comps/OzelMenuRaporlama";
import KarsilastirmaliGrafikChart from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikChart";
import KarsilastirmaDataTable from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaDataTable";
import KarsilastirmaliGrafikColChart
  from "@/components/infos/raporlama-ofispro/helper-comps/KarsilastirmaliGrafikColChart";
import TakipTahsilatWidget from "@/components/infos/raporlama-ofispro/TakipTahsilatWidget";
import TakipVeTahsilatKarsilastirmaliGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatKarsilastirmaliGrafik";
import TakipVeTahsilatWidgetGrafik from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatWidgetGrafik";
import OzelAralikDatePicker from "@/components/infos/raporlama-ofispro/helper-comps/OzelAralikDatePicker.vue";
import TakipVeTahsilatDataTable from "@/components/infos/raporlama-ofispro/TakipVeTahsilatDataTable";
import TakipVeTahsilatAyGrafik from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatAyGrafik.vue";
import TakipVeTahsilatHaftalikGrafik
  from "@/components/infos/raporlama-ofispro/helper-comps/TakipVeTahsilatHaftalikGrafik.vue";
@Component({
  components: {
    TakipVeTahsilatHaftalikGrafik,
    TakipVeTahsilatAyGrafik,
    TakipVeTahsilatDataTable,
    OzelAralikDatePicker,
    TakipVeTahsilatWidgetGrafik,
    TakipVeTahsilatKarsilastirmaliGrafik,
    TakipTahsilatWidget,
    KarsilastirmaDataTable,
    KarsilastirmaliGrafikChart,
    OzelMenuRaporlama,
    UstBasliklar, MuvekkilTableInfo, GenelIstatistikPerformansGrafik, TahsilatlarChart, TahsilatlarDataTable, Dates,GenelIstatistikGrafikInfo}
})
export default class KarsilastirmaliRaporlamaView extends Vue {

  selectedItem= null
  yillikKontrol:boolean = true
  aylikKontrol:boolean = false
  haftalikKontrol:boolean = false
  items= ["Geçen Yıl", "Bu Yıl","Geçen Ay","Bu Ay","Geçen Hafta","Bu Hafta"]



  selectItem(item) {
    this.selectedItem = item;
    if (item === "Bu Yıl" || item === "Geçen Yıl") {
      this.yillikKontrol = true;
      this.aylikKontrol = false;
      this.haftalikKontrol = false;
    }else if(item === "Bu Ay" || item === "Geçen Ay"){
      this.yillikKontrol = false;
      this.aylikKontrol = true;
      this.haftalikKontrol = false;
    }else if (item === "Bu Hafta" || item === "Geçen Hafta"){
      this.yillikKontrol = false;
      this.aylikKontrol = false;
      this.haftalikKontrol = true;
    }
  }

};
