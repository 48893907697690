
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({})
export default class KarsilastirmaliGrafikChart extends Vue {
  @Prop() series!: any[];
  @Prop({ default: () => ["#5D7285", "#D9D9D9"] }) colors!: string[];
  @Prop() labels!: string[]
  chartOptions: any = {};

  mounted() {
    this.initializeChartOptions();
  }

  initializeChartOptions() {
    this.chartOptions = {
      series: this.series,
      colors: ["#5D7285", "#5FB3F6"],
      labels: this.labels,
      fill: {
        colors: this.colors,
      },
      chart: {
        height:150,
        type: "donut",
      },
      plotOptions: {
        pie: {
          dataLabels: {
            offset: 0,
          },
        },
      },
      legend: {
        show: true,
        offsetX: 80,
        offsetY: -10,
        fontSize: '14px',
        fontFamily: 'Inter',
        itemMargin: {
          horizontal: 0,
          vertical: 6
        },
        markers: {
          width: 0,
          height: 0,
          strokeWidth: 0,
        },
      },
    };
  }
}
