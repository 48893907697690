
import {Vue, Component, Prop, Watch, Mixins} from "vue-property-decorator";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({

})
export default class TakipVeTahsilatWidgetGrafik extends Mixins(ObjectInputMixin){


  options = {
    series: [{
      name: 'Toplam Takip',
      data: {},
      color: '#5D7285',
    },
      {
        name: 'Toplam Tahsilat',
        data: {},
        color: '#5FB3F6',
      }],
    chart: {
      type: 'line',
    },
    stroke: {
      width: 3,
      curve: 'smooth'
    },
    xaxis: {
      categories: ['Ocak','Şubat','Mart','Nisan','Mayıs','Haziran','Temmuz','Ağustos','Eylül','Ekim','Kasım','Aralık'],
      labels: {
        rotate: -80,
        show: true,
        style: {
          fontSize: '10px',
        }
      }
    },
    fill: {
      colors: ["#2196F3"],
    },
    yaxis: {

      tickAmount: 5
    }
  };

  mounted() {
    this.fetchData()
  }

  async fetchData(){
    try {
      const response = await this.$http.post('/api/v1/takip-tahsilat-dosya-getir/',  {
        "userId" : 2,
        "basTar" : "2024-01-01",
        "bitTar" : "2024-12-31",
        "interval" : "yearly"
      });
      const data = response.data;


      const seriesData = Array(12).fill(0);
      const seriesDataTahsilat = Array(12).fill(0);

      for (const entry of data) {
        const monthIndex = entry.ay - 1;
        seriesData[monthIndex] = Number(entry.takipToplam);
        seriesDataTahsilat[monthIndex] = Number(entry.tahsilatToplam);
      }

      this.options.series = [
        {
          name: 'Toplam Takip',
          data: seriesData,
          color: '#5D7285',
        },
        {
          name: 'Toplam Tahsilat',
          data: seriesDataTahsilat,
          color: '#5FB3F6',
        }
      ];

    }catch (error) {
      console.error('hata:', error);
    }
  }
}
